@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700;900&family=Poppins:wght@400;600;700;900&display=swap');

input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

*, *::before, *::after {
    box-sizing: border-box;
}

html {
    box-sizing: border-box;
}

html, body {
    margin: 0;
    padding: 0;
    min-height: 100%;
}

body {
    background: #fff;
    color:#1b1c1e;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    padding:0px;
    margin:0 auto;
    height: 100%;
    line-height: 1.4;
    -webkit-font-smoothing: antialiased !important;
	text-rendering: optimizeLegibility;
	font-feature-settings: "kern";
	-webkit-font-feature-settings: "kern";
	-moz-font-feature-settings: "kern";
	-moz-font-feature-settings: "kern=1";
    -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, nav {
    font-family: 'Inter', sans-serif;
    font-weight:900;
}

h1 span, h2 span, h3 span, h4 span {
    color:#f15524;
}

#root {
    margin: 0;
    padding: 0;
    float: left;
    width: 100%;
}

main {
    width:100%;
    padding:70px 20px;
    text-align: left;
}

figure {
    background-color: #fdfbfa;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    float: left;
    height: 100%;
    overflow: hidden;
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 1;
    margin: 0;
    margin-bottom: 0px;
    padding: 0;
}

figure a {
    position: absolute;
    height:100%;
    width:100%;
    top: 0;
    left: 0;
    text-indent: -9999px;
    overflow: hidden;
}

a {
    color: #f15524;
    text-decoration: underline #ccc;
}

a:hover {
    color: #f15524;
    text-decoration: underline #0073a0;
}

.alternitive {
    background:#fafafa;
}

header {
    height:60px;
    margin:40px auto;
    position: relative;
    width: 90%;
    z-index: 10;
    top: 0;
    left: 0;
    background: #0f0f0f;
    border-bottom: 1px solid #0f0f0f;
    max-width: 1240px;
    text-align: center;
}

.wrapper {
    width: 90%;
    margin: 0px auto;
    max-width: 1240px;
}

.wrapperReikn {
    width: 90%;
    margin: 0px auto;
    max-width: 440px;
    text-align: center;
}

.velcomescreen {
    width: 100%;
    height:160px;
    background-image: url(./assets/img/thak_merki.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 100%;
    margin-top:20px;
}

.velcometext {
    font-size:16px;
}

.velcometext p:nth-child(1) {
    font-size:16px;
}

.umokkur_texti a {
    color:rgba(255,255,255,0.8);
    text-decoration:none;
}

.umokkur_texti a:hover {
    color:rgba(255,255,255,1);
}

.umokkur_texti p {
    margin: 5px 0 13px;
}

.umokkur_texti .fa {
    font-size:18px;
    margin:0 5px;
}

fa

.fmicon {
    width: 100%;
    height:100px;
    background-image: url(./assets/img/fm_icon.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 100%;
    margin-bottom: 40px;
}

a.next, button.next {
    display:inline-block;
    padding:10px 30px;
    background-color: #f3520a;
    border: 1px solid #fdfbfa;
    color:#fdfbfa;
    border-radius: 0px;
    font-weight: 600;
    text-decoration: none;
    transition: .30s ease-in-out;
    font-weight:700;
    margin-top: 10px;
}

a.next:hover, button.next:hover {
    background-color: #da4c0c;
}

a.createauser {
    display:inline-block;
    padding:10px 30px;
    background-color: #fdfbfa;
    border:1px solid #d3d3d3;
    color:#333;
    border-radius: 15px;
    font-weight: 600;
    text-decoration: none;
    transition: .30s ease-in-out;
}

a.createauser:hover {
    background-color: #fafafa;
    border:1px solid #b0b0b0;
}

.navlogin, .navcreateuser {
    display: none;
}

.navmenu {
    float:right;
}

a.navhome {
    height: 36px;
    width: 107px;
    margin:7px 0;
    display:inline-block;
    color:#fdfbfa;
    position: relative;
    left: 0;
    background-image: url(../src/assets/img/thakco_logo-01.png);
    background-size: cover;
    text-indent: -3000px;
    overflow: hidden;
    float: left;
}

a.navmenu  {
    color:#292f36;
    font-size: 33px;
}

a.navmenu span {
    font-size: 33px;
    margin-top: 3px;
}

input {
    border:1px solid #cbcbcb;
    color:#333;
    border-radius: 15px;
    padding:2px 8px;
}

input[type="text"], input[type="email"], input[type="password"], input[type="number"] {
    width:80%;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 18px;
}

input[type="button"] {
    background-color: #ff3364;
    color:#fdfbfa;
    border:1px solid #ff3364;
    font-weight: 600;
    padding:10px 30px;
    width: 80%;
    font-size: 18px;
    transition: .30s ease;
    cursor: pointer;
}

input[type="button"]:hover {
    background-color: #df1848;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #555;
    opacity: 0.6; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #555;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: #555;
}

h4.fm {
    margin:0 0 15px;
    font-size: 20px;
}

input.fm {
    width:100px;
    text-align: center;
    border: 1px solid #fdfbfa;
    background: transparent;
    border-radius: 0;
    color:#fdfbfa;
}

.donthaveaccount {
    color:#545252;
    margin:10px 0 2px;
    display: block;
    font-size: 14px;
}

.Rememberme {
    color:#545252;
    margin:10px 0 2px;
    display: block;
    font-size: 16px;
    text-align: left;
    padding: 0 10%;
}

.dashboard {
    padding:10px;
    text-align:left;
}

.welcome_user {
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight:700;
    margin-bottom: 20px;
}

.welcome_user span {
    color:#33bce1;
    font-size: 16px;
    font-weight:400;
}

.featuredmedia {
    width:100%;
    height:auto;
    margin:10px 0;
    display: inline-block;
}

.featuredmedia figure {
    width:100%;
    height: 240px;
    display: inline-block;
}

.services {
    width:100%;
}

.service {
    width:50%;
    display:inline-block;
    padding:10px;
    color:#b3b3b3;
}

.service a { 
    color:#231f20;
    text-decoration: none;
}

.service:nth-child(odd) { 
    padding:0px 10px 10px 0;
}

.service:nth-child(even) { 
    padding:0px 0px 10px 10px;
}

.service figure {
    border-radius: 8px;
    background-color:#F4E04D;
    height:100px;
}

.service.unactive figure {
    background-color:#e6e6e6;
}

.main {
    text-align:left;
    margin-bottom: 80px;
}

.main img {
    max-width:100%;
    height: auto;
}

.main iframe {
    max-width:100%;
    height: auto;
    min-height: 400px;
}

.transactions {
    width:100%;
    margin:20px 0;
}

.transaction {
    width:100%;
    margin:0px;
    border-bottom: 1px solid #eaebea;
}

.transaction .icon {
    width:50px;
    height:50px;
    display:inline-block;
    padding:10px 0;
    vertical-align: top;
    margin-bottom: 10px;
}

.transaction .icon span {
    width:40px;
    height:40px;
    border-radius: 20px;
    background-color:#F4E04D;
    display:inline-block;
    border:1px solid #b6b6b6;
}

.transaction .information {
    height:50px;
    display:inline-block;
    width:calc(100% - 150px);
    padding:10px 0;
    vertical-align: top;
    margin-bottom: 10px;
    color:#292f36;
    font-size: 16px;
    font-weight: 700;
}

.transaction .information span {
    color:#808080;
    display:block;
    font-size: 14px;
    font-weight: 400;
}

.transaction .amount {
    height:50px;
    width:100px;
    display:inline-block;
    text-align: right;
    padding:0;
    vertical-align: top;
    margin-bottom: 10px;
    line-height: 50px;
    color:#2b2b45;
    font-weight: 700;
    font-size: 16px;
}

ul.undirsidur {
    list-style: none;
    padding: 0;
    margin:0;
}

ul.undirsidur li {
    display:inline-block;
    margin:0 5px 5px 0;
}

ul.undirsidur a {
    color:#545252;
    display: inline-block;
    padding:3px 5px;
    border: 1px solid #ddd;
    text-decoration: none;
    border-radius: 5px;
    font-size: 15px;
}

ul.undirsidur a.active {
    color:#fdfbfa;
    background-color:#00b4da;
    border: 1px solid #00b4da;
}

button.val {
    background: rgb(59,58,94);
    background: linear-gradient(146deg, rgba(59,58,94,1) 0%, rgba(17,16,51,1) 100%);
    border: 1px solid #494864;
    border-radius: 5px;
    color:#fdfbfa;
    width: calc(33.333334% - 10px);
    margin:5px;
    padding:5px;
    font-weight:700;
    cursor: pointer;
}

button.val:hover {
    background:rgba(59,58,94,1);
}

ul.flokkar {
    list-style: none;
    padding: 0;
    margin: 0;
}

ul.flokkar li {
    display: inline-block;
    background: #2d2b49;
    border-radius: 5px;
    padding:5px 20px;
    color:#fdfbfd;
    margin: 0 10px;
}

ul.flokkar li.valin {
    background:#f0edda;
    color: #2d2b49;
}

ul.flokkar li a {
    color:#b9bace;
    text-decoration: none;
    font-weight: 700;
}

ul.flokkar li.valin a {
    color: #2d2b49;
}


h2.intro {
    margin: 20px 0 0px;
}

p.intro {
    font-size: 15px;
    margin: 5px 0 10px;
}

a.intro {
    display: inline-block;
    padding:5px 10px;
    border: 1px solid #e4bf45;
    border-radius: 5px;
    margin: 0px 0 30px;
    color:#e4bf45;
    text-decoration: none;
    font-weight: 400;
}

a.intro:hover {
    background:rgba(228,191,69,0.2);
    color:#fdfbfa;
}

a span.basket {
    color:#fdfbfa;
}

.karfanmin {
    position:fixed;
    width:calc(100% - 20px);
    bottom:10px;
    left:10px;
    background:rgba(255,255,255,0.1);
    border:1px solid rgba(255,255,255,0.2);
    padding:10px;
    max-width: 375px;
    border-radius:5px;
    left: calc(50% - 187px);
    text-align:left;
}

.karfanmin span {
    color:#e8bf41;
}

ul.hjalp {
    text-align:left;
    width:100%;
    margin-top:20px;
    display: inline-block;
}

.btn-primary {
    color: #1b1c1e;
    background-color: #fdfbfa;
    border: 2px solid #1b1c1e;
}

.btn {
    display: inline-block;
    margin-top: 30px;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    float:left;
    user-select: none;
    border-color: #F15524;
    border-style: solid;
    border-width: 1px;
    padding: 8px 16px;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    margin-top: 20px;
}

.btn:hover {
    background-color: #F15524;
    color:#fdfbfa;
    border-width: 1px;
    border-color: #F15524;
}

.btn-secondary {
    color: rgba(0,0,0,0.5);
    background-color: transparent;
    border-color: rgba(0,0,0,0.1);
    border-style: solid;
    border-width: 1px;
    z-index: 178;
    float: none;
    line-height: 10px;
    vertical-align: middle;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    padding: 8px 16px;
    margin-right: 20px;
}

.btn-secondary:hover {
    color: rgba(0,0,0,0.7);
    background-color: #fff;
    border-color: rgba(0,0,0,0.6);
}

.fatilbodtakki {
    width:100%;
    clear: both;
    display: block;
    float: left;
    text-align: center;
}

.btn-primary.fatilbod {
    color: #fdfbfa;
    background-color: #e15d2b;
    border-color: #e15d2b;
    float:none;
    margin-top: 0px;
    font-weight: bold;
    padding: .375rem 1.25rem;
}

.float-right {
    float: none;
}

.toggle {
    margin: 0 0 1.5rem;
    box-sizing: border-box;
    font-size: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: stretch;
}

.toggle input {
    width: 0;
    height: 0;
    position: absolute;
    left: -9999px;
}

.toggle input + label {
    margin: 0 4px;
    padding: 14px;
    flex:0 0 25% !important;
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    border: solid 1px #1b1c1e;
    background-color: #fdfdfd;
    font-family: 'Poppins', sans-serif;
    color:#1b1c1e;
    font-size: 1rem;
    line-height: 140%;
    font-weight: 600;
    text-align: center;
    box-shadow: 0 0 0 rgba(255, 255, 255, 0);
    transition: border-color 0.15s ease-out, color 0.25s ease-out, background-color 0.15s ease-out, box-shadow 0.15s ease-out;
    cursor: pointer;
}

.toggle.hverningein input + label {
    padding: 0.70rem 0;
    line-height: 100%;
    height:60px;
}

.toggle input + label span {
    font-size: 10px;
    display: inline-block;
    width:calc(100% + 40px);
    line-height: 1;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    margin:3px 0 0 -20px;
}

.aukalysing {
    width:calc(100% + 0px) !important;
    line-height: 1;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 10px;
    margin:5px 0;
    position: absolute;
    bottom: 7px;
}

.toggle input + label:first-of-type {
    border-radius: 0;
}

.toggle input + label:last-of-type {
    border-radius: 0;
}

.toggle input:hover + label {
    border-color: #191a1c;
}

.toggle input:checked + label {
    background-color: #191a1c;
    color: #fdfbfa;
    box-shadow: 0 0 10px rgba(102, 179, 251, 0.5);
    border-color: #191a1c;
    z-index: 1;
}

.toggle input:focus + label {
    outline: dotted 1px #ccc;
    outline-offset: 0.45rem;
}

h4.verd {
    margin:30px 0 0 0;
    font-size:24px;
    font-weight: 500;
}

article.adal .featuredmedia figure {
    height: 150px;
    border-radius: 5px;
}

article.adal h3 {
    display: inline-block;
    text-align: left;
    margin: 0;
    font-size: 15px;
    font-weight:500;
}

article.adal h3 a {
    color:#fdfbfa;
    text-decoration:none;
}

div.verd {
    margin:0px 0 30px;
    font-size:24px;
    font-weight: 400;
    text-decoration: underline dotted;
}

.thak {
    height:16px;
    width:calc(100% - 40px);
    margin: 100px 20px 80px;
    border:1px solid rgba(0,0,0,0.1);
    background-color: rgba(0,0,0,0.1);
    position: relative;
}

.baran {
    background-image: url(./assets/img/baran_1.png);
    width:300px;
    height: 177px;
    background-size: cover;
    margin: 0 auto 54px;
}

.baran.medbordklaedningu {
    background-image: url(./assets/img/timbur_timbur.png);
    width:300px;
    height: 177px;
    background-size: cover;
    margin: 0 auto 54px;
}

.gradurstika {
    background-image: url(./assets/img/gradustika.png);
    width:300px;
    height: 217px;
    background-size: 170%;
    margin: 0 auto 14px;
    background-position: center;
}

.gradurstika20plus {
    background-image: url(./assets/img/gradustika-20plus.png);
    width:300px;
    height: 217px;
    background-size: 170%;
    margin: 0 auto 14px;
    background-position: center;
}

.gradurstika10_20 {
    background-image: url(./assets/img/gradustika-1020.png);
    width:300px;
    height: 217px;
    background-size: 170%;
    margin: 0 auto 14px;
    background-position: center;
}

select#flatarmal, select#holdur, select#fjoldi, select#hurdarhunn, select#samsetning {
    padding: 10px 20px;
    border: 1px solid #1b1c1e;
    background-color:transparent;
    color: #1b1c1e;
    font-size:18px;
    -webkit-appearance:none;
}

input#flatarmal, input#flatarmalefri {
    border: 1px solid #1b1c1e;
    background-color:transparent;
    color: #1b1c1e;
    font-size:20px;
    -webkit-appearance:none;
    max-width: 100px;
    border-radius: 4px;
    line-height: 40px;
}

.stepsbar {
    width:100%;
    margin:10px 0;
    text-align: left;
}

.stepsbar span {
    display: inline-block;
    width: 40px;
    height: 40px;
    border:1px solid #cbcbcb;
    border-radius: 20px;
    line-height: 40px;
    text-align: center;
}


.baran.alozink {
    background-image: url(./assets/img/baran_2.png);
}

.baran.litadal {
    background-image: url(./assets/img/baran_2_litad.png);
}

.baran.ja {
    height: 231px;
    margin: 0 auto 0px;
}

.textalignc {
    text-align: center;
}

.baran.uppstolad{
    background-image: url(./assets/img/baran_3.png);
}

.baran.litadal.uppstolad {
    background-image: url(./assets/img/baran_3_litad.png);
}

.thak.timbur {
    background-color: #d2a770;
    border:1px solid #d2a770;
}

.thak.stal {
    background-color: #e8e9eb;
    border:1px solid #e8e9eb;
}

.thak.steypa {
    background-color: #808487;
    border:1px solid #808487;
}

.thak.medkanti::before {
    position:absolute;
    right: -1px;
    height: 130px;
    width: 14px;
    content:"";
    top: -65px;
}

.thak.medkanti::after {
    position:absolute;
    left:-1px;
    height:130px;
    width:14px;
    content:"";
    top: -65px;
}

.thak.ankanti::before {
    position:absolute;
    right: -1px;
    height: 55px;
    width: 14px;
    content:"";
    top: 10px;
}

.thak.ankanti::after {
    position:absolute;
    left:-1px;
    height:55px;
    width:14px;
    content:"";
    top: 10px;
}

.thak.timbur.medkanti::after, .thak.timbur.ankanti::after { background-color: #d2a770; } 
.thak.timbur.medkanti::before, .thak.timbur.ankanti::before { background-color: #d2a770; }
.thak.stal.medkanti::after, .thak.stal.ankanti::after { background-color: #e8e9eb; }
.thak.stal.medkanti::before, .thak.stal.ankanti::before { background-color: #e8e9eb; }
.thak.steypa.medkanti::after, .thak.steypa.ankanti::after { background-color: #808487; }
.thak.steypa.medkanti::before, .thak.steypa.ankanti::before  { background-color: #808487; }

.thakpappix2 {
    height:8px;
    width:calc(100% - 34px);
    margin: -13px 0px 0 17px;
    border-bottom:3px solid #333;
    border-top:1px solid #333;
    position: relative;
}

.thakpappix2 span {
    font-size: 12px;
    background-color: #fdfbfa;
    position: absolute;
    top: -5px;
    left: calc(50% - 36px);
    padding: 0px 5px;
}

.thakpappix2.ja:after {
    width:100%;
    height:1px;
    content: "";
    background:#333;
    top:-11px;
    position: absolute;
    left:0;
}

.thakpappix2.ja::before {
    width:100%;
    height:1px;
    content: "";
    top:-18px;
    position: absolute;
    border-top: 1px dotted #1b1c1e;
    left:0;
}

.thakpappix2.m_ull {
    margin: -40px 0px 0 17px;
}

.ull {
    height:18px;
    width:calc(100% - 34px);
    margin: 4px 0px 0 17px;
    border-bottom:1px solid #fdfbfa;
    border-top:0px solid #fdfbfa;
    position: relative;
    background-image: url(./assets/img/layer_ull.png);
    background-size: auto 12px;
    padding-bottom: 10px;
    background-repeat: repeat-x;
}

.ull_n {
    display: none;
    font-size: 12px;
    background-color: #fdfbfa;
    position: absolute;
    top: -2px;
    left: calc(50% - 16px);
    padding: 0px 5px;
}

.ull_nr {
    display: none;
    font-size: 12px;
    background-color: #fdfbfa;
    position: absolute;
    top: 11px;
    left: calc(50% - 37px);
    padding: 0px 5px;
}

.ull .ull_n, .ull .ull_nr {
    display: inline-block;
}


.xps {
    height:16px;
    width:calc(100% - 34px);
    margin: -50px 0px 0 17px;
    border-top:2px dotted #333;
    border-bottom:1px solid #333;
    position: relative;
}

.xps_n {
    display: none;
    font-size: 13px;
    background-color: #fdfbfa;
    position: absolute;
    top: 20px;
    left: calc(50% - 21px);
    padding: 0px 5px;
    z-index: 3;
}

.xps_d {
    display: none;
    font-size: 13px;
    background-color: #fdfbfa;
    position: absolute;
    top: 5px;
    left: calc(50% - 41px);
    padding: 0px 5px;
}

.xps_m {
    display: none;
    font-size: 13px;
    background-color: #fdfbfa;
    position: absolute;
    top: -9px;
    left: calc(50% - 21px);
    padding: 0px 5px;
}

.xps_d_line {
    height:1px;
    width:100%;
    position: absolute;
    top:28px;
    left:0;
    background-color:#333;
    display: none;
}


.xps .xps_n, .xps .xps_d, .xps .xps_m,
.xps .xps_d_line  {
    display: inline-block;
}

.thak_valid {
    background-image: url(../src/assets/img/thak_timbur.png);
    height:100px;
    width:100%;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.thak_kanti_valid {
    background-image: url(../src/assets/img/thak_kant_timbur.png);
    height:100px;
    width:100%;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
}

label.tilbodlabel {
    width:100%;
    font-weight: bold;
    display: inline-block;
    text-align:left;
}

.utskyring {
    margin:5px 0 10px;
    text-align:center;
    color:#333;
    font-size: 16px;
    width: 100%;
    display: inline-block;
    clear: both;
    line-height: 1.3;
}

.torfdren_n {
    display: none;
    font-size: 12px !important;
    background-color: #fdfbfa !important;
    position: absolute !important;
    top: -22px !important;
    left: calc(50% - 56px) !important;
    padding: 0px 5px !important;
    z-index: 5;
}

.thakpappix2.ja .torfdren_n { display: block }

input.next {
    display: inline-block;
    padding: 10px 30px;
    background-color: #f3520a;
    color: #fdfbfa;
    border-radius: 0px;
    font-weight: 600;
    text-decoration: none;
    transition: .30s ease-in-out;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    margin-top: 10px;
    border: none;
}

.inputform {
    border-radius: 0;
    background-color: transparent !important;
    width: 100% !important;
    margin-bottom: 10px  !important;
    color: #1b1c1e;
}

.postcode {
    width:calc(30% - 20px) !important;
    float:left;
    margin-right: 20px;
}

.athugasemdir {
    height:80px;
    border-radius: 0;
    background-color: transparent !important;
    width: 100% !important;
    margin-bottom: 10px  !important;;
    color: #1b1c1e;
    font-size: 16px;
    padding:10px;
    border: 1px solid #1b1c1e;
}



.stadur {
    width:70% !important;
    float:left;
}

@media (max-width: 800px) {
    .toggle input + label {
        padding: 0.75rem 0.25rem;
        flex: 0 0 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.halli1020 {
    background-color: transparent !important;
    background-image: url(./assets/img/gradustika-1020.png) !important;
    background-size: 120px auto !important;
    width: 200px !important;
    height: 70px !important;
}


.halli20plus {
    background-color: transparent !important;
    background-image: url(./assets/img/gradustika-20plus.png) !important;
    background-size: 120px auto !important;
    width: 200px !important;
    height: 70px !important;
}

select option {
    background: #1b1c1e;
    color:#fdfbfa;
}

header menu {
    display: inline-block;
    margin: 0 auto;
    padding: 0;
}

header menu ul {
    display: inline-block;
    margin: 20px auto 0;
    list-style: none;
    padding: 0;
}

header menu ul li {
    display: inline-block;
    margin: 0px 20px;
}

header menu ul li a {
    color:#fdfbfa;
    text-decoration: none;
}

header menu ul li a:hover, header menu ul li a.valin {
    font-weight: bold;
}

.inngangur .mynd {
    display: inline-block;
    width:50%;
    min-height:600px;
    vertical-align: top;
}

.inngangur .mynd figure {
    width:100%;
    min-height:560px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.inngangur .textinn {
    display: inline-block;
    width:50%;
    min-height:600px;
    padding: 40px 80px;
    vertical-align: top;
}

.inngangur .textinn h2 {
    font-size: 64px;
    padding: 0;
    margin: 0;
    line-height: 1.05;
}

.hafdusamband {
    display: inline-block;
    font-size: 16px;
    background-color: transparent;
    border: 1px solid #fdfbfa;
    padding: 10px 20px;
    color: #fdfbfa;
    -moz-box-align: center;
    align-items: center;
    text-align: center;
    text-decoration: none;
    margin: 10px 0 0 20px;
}

.reiknadu_ut {
    background-color: #fdfbfa;
    padding:60px 20px;
}

.reiknadu_ut .textinn {
    display: inline-block;
    width:50%;
    vertical-align: middle;
}

.reiknadu_ut .takkinn {
    display: inline-block;
    width:50%;
    vertical-align: middle;
    text-align: center;
}

.reiknadu_ut .textinn h2 {
    display: inline-block;
    color: black;
    font-size: 60px;
    padding: 0 60px;
    line-height: 1.05;
    margin-bottom:0;
    font-weight: 900;
}

.reiknadu_ut .textinn h2 strong {
    position: relative;
    z-index: 1;
    font-weight: 900;
    display: inline-block;
}

.reiknadu_ut .textinn h2 strong:after {
    position:absolute;
    content:"";
    bottom:8px;
    height: 8px;
    width: 100%;
    background-color: #F15524;
    left: 0;
    z-index: -1;
}

.reiknadu_ut .textinn h4 {
    display: inline-block;
    color: black;
    padding: 0 60px;
    line-height: 1.05;
    margin:0 0 40px;
    font-weight: 900;
    font-size: 16px;
    margin-top: 4px;
}

.frekarupplysingar {
    padding: 150px 0 0;
}

.frekarupplysingar.l {
    padding: 150px 0 0;
}


.frekarupplysingar .textinn {
    width:65%;
    display: inline-block;
    vertical-align: top;
    padding-left: 60px;
}

.frekarupplysingar a {
    text-decoration: none;
    font-weight: bold;
}

.frekarupplysingar.l .textinn {
    padding-right: 60px;
    padding-left: 0px;
}

.frekarupplysingar .mynd {
    width:35%;
    display: inline-block;
    vertical-align: top;
}

.frekarupplysingar .mynd figure {
    height:230px;
    width:100%;
    background-size: cover;
}

h2.thakbara {
    position: relative;
    text-indent: 60px;
    margin-top: 10px;
}

h2.thakpappi {
    position: relative;
    text-indent: 40px;
    margin-top: 10px;
}

h2.thakbara::before {
    position: absolute;
    content: "";
    height:20px;
    width:46px;
    background-image: url("./assets/img/kindpng_762872 1.svg");
    background-repeat: no-repeat;
    background-size: cover;
    left:0;
    top: 6px;
}

h2.thakpappi::before {
    position: absolute;
    content: "";
    height:28px;
    width:28px;
    background-image: url("./assets/img/thakpappi.svg");
    background-repeat: no-repeat;
    background-size: cover;
    left:0;
    top: 0px;
}

footer {
    height: 130px;
    background-image: url("./assets/img/line.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    margin-bottom:20px;
    text-decoration: none;
    margin-top: 150px;
}

footer .top {
    width: 100%;
    height: 100px;
}

footer .isDzJv {
    float: right;
}

footer menu {
    padding: 0;
    margin: 0;
    display: inline-block;
}

footer menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

footer menu ul li {
    display: inline-block;
    margin-right:10px;
}

footer menu ul li a {
    color:rgba(255,255,255,0.5);
    text-decoration: none;
}

footer menu ul li a:hover {
    color:rgba(255,255,255,1);
    text-decoration: none;
}

.social {
    float: right;
}

.social a {
    color:rgba(255,255,255,0.5);
    display:inline-block;
    margin-left:20px;
    text-decoration: none;
}

.social a:hover {
    text-decoration: none;
}

.social a.icons {
    color:rgba(255,255,255,1);
    display:inline-block;
    margin-left:20px;
}

ul.val_reiknivelar {
    list-style-type: none;
    padding: 0;
    margin:10px 0;
}

ul.val_reiknivelar li {
    display: inline-block;
    padding: 0 20px 20px 0;
    min-width: 120px;
}

/*
    þakco: rgba(247,69,29,1)
    fagco: rgba(245,190,49,1)
*/

.thakcolor {
    color: rgba(247,69,29,1);
}

.fagcolor {
    color: rgba(245,190,49,1);
}

ul.val_reiknivelar li a {
    display: block;
    border:2px solid rgba(245,190,49,0.3);
    background-color: rgba(254, 254, 254, 0.8);
    border-radius: 4px;
    padding: 4px 8px;
    color:#1e191a;
    text-decoration: none;
    font-size: 14px;
    transition: 0.3s ease-in-out;
}

ul.val_reiknivelar li a:hover { 
    border:2px solid rgba(245,190,49,1);
    background-color: rgba(254, 254, 254, 1);
}

ul.val_reiknivelar.thakval li a { 
    border:2px solid rgba(247,69,29,0.3);
}

ul.val_reiknivelar.thakval li a:hover {
    border:2px solid rgba(247,69,29,1);
}

ul.val_reiknivelar li a.selected {
    background:#fcddd3;
    border-color: #f15524;
}

.val_thakpappi .icon {
    width:100%;
    height: 70px;
    display: block;
    background-image: url(../src/assets/img/thakco_icons_thakpappi.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.val_thakbara .icon {
    width:100%;
    height: 70px;
    display: block;
    background-image: url(../src/assets/img/thakco_icons_thakbara.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.val_parket .icon {
    width:100%;
    height: 70px;
    display: block;
    background-image: url(../src/assets/img/thakco_icons_parket.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.val_innihurdir .icon {
    width:100%;
    height: 70px;
    display: block;
    background-image: url(../src/assets/img/thakco_icons_inniveggir.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.val_milliveggir .icon {
    width:100%;
    height: 70px;
    display: block;
    background-image: url(../src/assets/img/thakco_icons_milliveggir.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.val_innrettingar .icon {
    width:100%;
    height: 70px;
    display: block;
    background-image: url(../src/assets/img/thakco_icons_innrettingar.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}


.validreikn {
    text-align: left;
}

.backhome {
    display: inline-block;
    float: right;
    height:34px;
    width: 25px;
    background-image: url(./assets/img/house-light.svg);
    background-size: 100% auto;
    font-size: 0;
    background-repeat: no-repeat;
    background-position: center;
}

.skapaflotur {
    width:100%;
    height:200px;
    border: 1px solid #ccc;
    position: relative;
    background-color: #fff;
}

.skapaflotur .lme {
    position: absolute;
    width:100%;
    height:21px;
    line-height: 21px;
    bottom: 60px;
    left:0;
    font-size: 14px;
    font-weight: 500;
}

.skapaflotur .lmeefri {
    position: absolute;
    width:100%;
    height:21px;
    line-height: 21px;
    top: 50px;
    left:0;
    font-size: 14px;
    font-weight: 500;
}

.skapaflotur .fme {
    position: absolute;
    width:60px;
    height:60px;
    line-height: 50px;
    top: 10px;
    right:10px;
    font-size: 14px;
    font-weight: 700;
    background-color: #1b1c1e;
    color:#f4bd31;
    border-radius: 30px;
}

.skapaflotur .lme::before, .skapaflotur .lmeefri::before {
    content: "";
    position: absolute;
    width: calc(100% - 10px);
    height:1px;
    background-color: #333;
    top:10px;
    left: 5px;
    z-index: 0;

}

.skapaflotur .lme span, .skapaflotur .lmeefri span { display: inline-block; background-color: #fff; padding: 0 6px; position: relative; z-index: 1; font-weight: 900;}

.skapaflotur .lme span i, .skapaflotur .lmeefri span i { display: inline-block; padding: 0; position: absolute; z-index: 1; font-weight: 300;text-align: center;width: 80%; top:-17px; color:rgba(0,0,0,0.5); font-style: normal; }

.half {
    display: inline-block;
    width: 50%;
    vertical-align: top;
}


.parketflotur {
    width:100%;
    height:200px;
    border: 1px solid #ccc;
    position: relative;
    background-image: url(../src/assets/img/thakco_hefdbundinn_icons.png);
    background-color: #fff;
    background-size: auto 100% ;
    background-position: center;
}

.parketflotur.logn0 {
    background-image: url(../src/assets/img/thakco_hefdbundinn_icons.png);
    background-color: #fff;
    background-size: auto 100%;
    background-position: center;
}

.parketflotur.logn1 {
    background-image: url(../src/assets/img/thakco_fiskibein_icons.png);
    background-color: #fff;
    background-size: auto 100%;
    background-position: center;
}

.parketflotur .fme {
    position: absolute;
    line-height: 24px;
    bottom: 10px;
    left:10px;
    font-size: 14px;
    font-weight: 700;
    color:#1b1c1e;
    width:77px;
    height:31px;
    text-align: center;
    background-image: url(../src/assets/img/fm.png);
}

.hurdarflotur {
    width:100%;
    height:200px;
    border: 1px solid #ccc;
    position: relative;
    background-image: url(../src/assets/img/hurd_tegund_icon.png);
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center;
    background-color: #fff;

}

.hurdarflotur.hurd_5 {
    background-image: url(../src/assets/img/hurd_tegund_renni_icon.png);
}

.hurdarflotur.hurd_5 .hurdahunnx { display: none; }

.hurdarflotur .fme {
    position: absolute;
    padding: 5px 10px;
    line-height: 34px;
    width:44px;
    height: 44px;
    bottom: 10px;
    left:10px;
    font-size: 14px;
    font-weight: 700;
    background-color: #1b1c1e;
    color:#f4bd31;
    border-radius: 80px;
}


.hurdarflotur .fme span { color:#fdfdfd; }

.skapaflotur .linur {
    position: relative;
    width:100%;
    height:200px;
}

.skapaflotur .linur span {
    width:1px;
    height:calc(100% - 1px);
    background-color: #dddddd;
    position: absolute;
    top:0px;
    left:64px;
}

.skapaflotur .linur span#lina1, .skapaflotur .linur span#lina2, .skapaflotur .linur span#lina3, .skapaflotur .linur span#lina4, .skapaflotur .linur span#lina5, .skapaflotur .linur span#lina6 {
    display: none;
}

.skapaflotur .linur.show2 span#lina1 {
    left:50%;
    display: block;
}

.skapaflotur .linur.show3 span#lina1 {
    left:33.3333334%;
    display: block;
}

.skapaflotur .linur.show3 span#lina2 {
    left:66.6666668%;
    display: block;
}

.skapaflotur .linur.show4 span#lina1 {
    left:25%;
    display: block;
}

.skapaflotur .linur.show4 span#lina2 {
    left:50%;
    display: block;
}

.skapaflotur .linur.show4 span#lina3 {
    left:75%;
    display: block;
}

.skapaflotur .linur.show5 span#lina1 {
    left:20%;
    display: block;
}

.skapaflotur .linur.show5 span#lina2 {
    left:40%;
    display: block;
}

.skapaflotur .linur.show5 span#lina3 {
    left:60%;
    display: block;
}

.skapaflotur .linur.show5 span#lina4 {
    left:80%;
    display: block;
}


.skapaflotur .linur.show6 span#lina1 {
    left:16.6666666667%;
    display: block;
}

.skapaflotur .linur.show6 span#lina2 {
    left:33.333333333%;
    display: block;
}

.skapaflotur .linur.show6 span#lina3 {
    left:50%;
    display: block;
}

.skapaflotur .linur.show6 span#lina4 {
    left:66.666666%;
    display: block;
}

.skapaflotur .linur.show6 span#lina5 {
    left:83.3333333%;
    display: block;
}

.skapaflotur .linur.show7 span#lina1 {
    left:14.28%;
    display: block;
}

.skapaflotur .linur.show7 span#lina2 {
    left:28.5714285714%;
    display: block;
}

.skapaflotur .linur.show7 span#lina3 {
    left:42.8571428571%;
    display: block;
}

.skapaflotur .linur.show7 span#lina4 {
    left:57.1428571428%;
    display: block;
}

.skapaflotur .linur.show7 span#lina5 {
    left:71.4285714285%;
    display: block;
}

.skapaflotur .linur.show7 span#lina6 {
    left:85.7142857142%;
    display: block;
}

h4.medutskyringu {
    margin-bottom: 0;
    font-size: 18px;
}

h4.medutskyringu.auka {
    font-size: 17px;
    margin: 5px 0;
}

.utskyring.top {
    margin-top: 2px;
    margin-bottom: 30px;
}

#ekkiundirlag {
    flex: 0 0 33.3333% !important;
}

#b30, #b60 {
    flex: 0 0 50% !important;
}

.tegundicon {
    border:1px solid #333;
    height:40px;
    width:40px;
    border-radius: 20px;
    position: absolute;
    right:10px;
    bottom:10px;
    background-color: #fff;
}

.tegundicon.tegund_0 {
    background-image: url(../src/assets/img/parket_harvidar_icon.png);
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center;
}

.tegundicon.tegund_1 {
    background-image: url(../src/assets/img/parket_vidarparket_icon.png);
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center;
}

.tegundicon.tegund_2 {
    background-image: url(../src/assets/img/parket_vinil_icon.png);
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center;
}

.tegundicon.tegund_hurd_2, .tegundicon.tegund_hurd_3 {
    background-image: url(../src/assets/img/hurd_tegund_eld_icon.png);
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center;
}

.tegundicon.tegund_hurd_1, .tegundicon.tegund_hurd_4 {
    background-image: url(../src/assets/img/hurd_tegund_eld_icon.png);
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.4;
}

.tegundicon.tegund_hurd_5 {
    background-image: url(../src/assets/img/hurd_tegund_eld_icon.png);
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.4;
}

.forgunonoff {
    border:1px solid #333;
    height:40px;
    width:40px;
    border-radius: 20px;
    position: absolute;
    right:56px;
    bottom:10px;
    background-color: #fff;
}

.forgunonoff.h {
    bottom:56px;
    right:10px;
}

.forgunonoff.erforgun_0 {
    background-image: url(../src/assets/img/rifogforgun_icon.png);
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.3;
}

.forgunonoff.erforgun_1 {
    background-image: url(../src/assets/img/rifogforgun_icon.png);
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 1;
}

.limingonoff {
    border:1px solid #333;
    height:40px;
    width:40px;
    border-radius: 20px;
    position: absolute;
    right:103px;
    bottom:10px;
    background-color: #fff;
}

.limingonoff.erlim_0 {
    background-image: url(../src/assets/img/parket_liming_icon.png);
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.3;
}

.limingonoff.erlim_1 {
    background-image: url(../src/assets/img/parket_liming_icon.png);
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 1;
}

.throskuldur {
    border:1px solid #333;
    height:40px;
    width:40px;
    border-radius: 20px;
    position: absolute;
    bottom:103px;
    right:10px;
    background-color: #fff;
}

.throskuldur.er_0 {
    background-image: url(../src/assets/img/hurd_throskuldur_icon.png);
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 1;
}

.throskuldur.er_1 {
    background-image: url(../src/assets/img/hurd_throskuldur_icon.png);
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.3;
}

.throskuldur.er_2 {
    background-image: url(../src/assets/img/hurd_throskuldur_icon.png);
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.3;
}


.lognonoff {
    border:1px solid #333;
    height:40px;
    width:40px;
    border-radius: 20px;
    position: absolute;
    right:205px;
    bottom:10px;
    background-color: #fff;
    display: none;
}

.lognonoff.erfisk_0 {
    background-image: url(../src/assets/img/thakco_hefdbundinn_icons.png);
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}

.lognonoff.erfisk_1 {
    background-image: url(../src/assets/img/thakco_fiskibein_icons.png);
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}

.undirlagid {
    border:1px solid #333;
    height:40px;
    width:40px;
    border-radius: 20px;
    position: absolute;
    right:153px;
    bottom:10px;
    background-color: #fff;
}

.undirlagid.erundirlag_1 {
    background-image: url(../src/assets/img/parket_undirlag.png);
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.3;
}

.undirlagid.erundirlag_0, .undirlagid.erundirlag_2 {
    background-image: url(../src/assets/img/parket_undirlag.png);
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 1;
}

.hurdahunnx {
    border:1px solid #333;
    height:40px;
    width:40px;
    border-radius: 20px;
    position: absolute;
    right: 210px;
    bottom: 71px;
    background-color: #fff;
    line-height: 37px;
    font-weight: 600;
}

.hurdahunnx.f0 { display: none; }

.hurdahunnx span { color:rgba(0,0,0,0.4); }

.val_milliveggir {display: none !important; }


.innrif {
    border: 1px solid #333;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    position: absolute;
    right: 10px;
    bottom: 15px;
    background-color: #fff;
    z-index: 10;
}

.innrif.errif1 {
    background-image: url(../src/assets/img/innrettingar_rif_icon.png);
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: 50%;
}
.innrif.errif0 {
    background-image: url(../src/assets/img/innrettingar_rif_icon.png);
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: 50%;
    opacity: 0.3;
}

.innsam {
    border: 1px solid #333;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    position: absolute;
    right: 55px;
    bottom: 30px;
    background-color: #fff;
    z-index: 10;
    display: none;
}

.innsam.ersam1 {
    background-image: url(../src/assets/img/innrettingar_sam_icon.png);
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: 50%;
}
.innsam.ersam0 {
    background-image: url(../src/assets/img/innrettingar_sam_icon.png);
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: 50%;
    opacity: 0.3;
}

.innupp {
    border: 1px solid #333;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    position: absolute;
    right: 101px;
    bottom: 30px;
    background-color: #fff;
    z-index: 10;
    display: none;
}

.innupp.erupp1 {
    background-image: url(../src/assets/img/innrettingar_upp_icon.png);
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: 50%;
}
.innupp.erupp0 {
    background-image: url(../src/assets/img/innrettingar_upp_icon.png);
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: 50%;
    opacity: 0.3;
}

.villa {
    color:red;
    font-size: 14px;
}

.innvaskur {
    border: 1px solid #333;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    position: absolute;
    right: 55px;
    bottom: 15px;
    background-color: #fff;
    z-index: 10;
}

.innvaskur.ervaskur1 {
    background-image: url(../src/assets/img/innrettingar_vaskur_icon.png);
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: 50%;
}
.innvaskur.ervaskur0 {
    background-image: url(../src/assets/img/innrettingar_vaskur_icon.png);
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: 50%;
    opacity: 0.3;
}

.inneldavel {
    border: 1px solid #333;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    position: absolute;
    right: 100px;
    bottom: 15px;
    background-color: #fff;
    z-index: 10;
}

.inneldavel.ereldavel1 {
    background-image: url(../src/assets/img/innrettingar_eldavel_icon.png);
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: 50%;
}

.inneldavel.ereldavel0 {
    background-image: url(../src/assets/img/innrettingar_eldavel_icon.png);
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: 50%;
    opacity: 0.3;
}

@media only screen and (max-width: 980px) {
    header {
            height: 50px;
            margin: 10px auto;
    }

    header menu { display: none; }

    header .isDzJv { 
        background-color: transparent;
        border: 1px solid #fdfbfa;
        font-size:0;
        padding: 10px;
        margin: 0;
    }

    header .isDzJv svg { 
        float: left;
        margin-right: 0px;
    }

    a.navhome {
        float: none;
        margin:0 auto;
    }

    .inngangur .mynd {
        width:100%;
        min-height: 260px;
    }

    .inngangur .textinn, .reiknadu_ut .textinn, .reiknadu_ut .takkinn {
        width:100%;
        padding: 0;
    }

    .inngangur .textinn h2, .reiknadu_ut .textinn h2 {
        width:100%;
        padding: 0;
        font-size: 44px;
    }

    .reiknadu_ut .textinn h4 {
        padding: 0px;
    }

    .inngangur .mynd figure {
        width: 100%;
        min-height: 240px;
    }

    .inngangur .takkar {
        width: 100%;
        text-align: center;
    }

    .inngangur .takkar a {
        margin: 10px 0;
    }

    .frekarupplysingar {
        padding: 100px 0 0;
    }

    .frekarupplysingar.l {
        padding: 100px 0 0;
    }

    .frekarupplysingar .mynd {
        width: 100%;
        display: inline-block;
        vertical-align: top;
        margin-bottom: 20px;
    }

    .frekarupplysingar .textinn, .frekarupplysingar.l .textinn {
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
    }

    footer { 
        margin-top: 50px;
    }

    footer .top {
        text-align: center;
        height: 80px;
    }

    footer menu {display: none;}

    footer .isDzJv {display: none;}

    footer .social { float: none; text-align: center; }
    footer .social a { display: none; }
    footer .social a.icons { display: inline-block; margin: 0 5px; }
}
@media only screen and (max-width: 480px) {
    .hurdahunnx {
        right: 170px;
        bottom: 71px;
    }
}